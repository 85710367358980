.imageTextWrap{
  display: flex;
  align-items: center;
  // cursor: default;
  // &:hover{
  //   .pName{
  //     text-decoration: underline;
  //   }
  // }
  .tableImage{
    width: 136px;
    height: 94px;
  }
  .textWrap{
    flex: 1;
    padding-left: 15px;
  }
  .pName{
    display: block;
    font-weight: bold;
    font-size: 18px;
  }
  .lastUpdated{
    display: block;
    color: #BBB6B6;
    font-size: 14px;
  }
}