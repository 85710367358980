@import 'variable';

// @import url("https://fonts.googleapis.com/css?family=Open+Sans");

.chipMainWrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  padding: 6px 10px;
  &:hover{
    // border: 1px solid #444;
  }
  .input {
    flex: 1;
    box-sizing: border-box;
    font: inherit;
    border: none;
    background: transparent;
    color: #565656;
    -webkit-appearance: none;
    margin: 4px 0;
    &:focus{
      border-color: cornflowerblue;
      outline: none;
    }
  }
  .tag-item {
    background-color: #e0e0e0;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 3px 3px 3px 0;
    &>.chipButton{
      background-color: #a6a6a6;
      color: #e0e0e0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      font-size: 18px;
      margin-left: 10px;
      font-weight: bold;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-in;
      &:hover{
        background-color: darken(#a6a6a6, 10%);
      }
      &:focus{
        outline: none;
      }
    }
  }
}

