@import '_variable.scss';
.formHeadingWrap{
  margin: 16px 16px;
  h2{
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: 400;
  }
  .formSubheading{
    color: #9F9F9F;
    font-size: 0.857rem;
  }
}
.leftItem{
  position: relative;
}
.rightItem{
  position: absolute;
  right:15px;
  width:20%;
}
.formControl{
  // display: inline-block;
  &.multiSelectBox{
    position: relative;
    &.selectedTrue{
      >label{
        top: 0;
        font-size: 12px;
        background-color: #fff;
        padding: 5px 6px;
        z-index: 2;
      }
    }
    >label{
      font-size: 14px;
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      transition: all 0.23s ease-in;
    }
    .MuiInputBase-root{
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      padding: 4.5px 14px;
      &:hover{
        &::before, &:after{
          border-bottom: unset;
        }
      }
      &::before, &::after{
        border-bottom: unset;
      }
    }
  }
  .MuiOutlinedInput-multiline{
    padding: 14px 0;
  }
  .MuiFormControl-root{
    width: 100%;
  }
  >.MuiInputBase-root{
    width: 100%;
  }
  .photoBox{
    width: 100px;
    object-fit: cover;
    margin-bottom: 10px;
    overflow: hidden;
    border: 1px solid #eaeaea;
    padding: 4px;
    box-sizing: border-box;
  }
  .MuiInputLabel-outlined{
    font-size: 14px;
    transform: translate(14px, 15px) scale(1);
    &.MuiInputLabel-shrink{
      transform: translate(18px, -6px) scale(0.75);
    }
  }
  .MuiOutlinedInput-input{
    padding: 12.5px 14px;
  }
  &.imageUpload{
    .MuiFormControl-root{
      display: none;
    }
    .MuiInputBase-root{
      &::before, &::after{
        border: none;
      }
    }
    input[type="file"]{
      display: none;
    }
    .customFileUpload{
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      padding: 7.5px 14px;
      >span{
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }
  
}
.exportForm{
  padding:10px 0;
}
.exportText{
  color:#9F9F9F;
  font-size:16px;
  padding-top: 10px;
}
.divider{
  margin:30px 0 !important;
}
.customFormPage{
  .rightCard{
    padding-bottom: 10px;
    .head{
      display: block;
      // font-weight: bold;
      margin-bottom: 5px;
    }
    .description{
      font-size: 12px;
      color: #BBB6B6;
      display: inline-block;
      margin-bottom: 10px;
    }
    .formControl{
      margin-top: 5px;
      input{
        font-size: 14px;
      }
      .MuiSelect-selectMenu{
        font-size: 14px;
      }
    }
    .MuiOutlinedInput-adornedEnd{
      padding-right: 0;
    }
    .btnWrap{
      display: block;
      margin-top: 10px;
      .whiteBtn{
        background-color: #fff;
        padding: 5px 10px;
        font-size: 13px;
        transition: all 0.25s ease-in;
        border: 1px solid #CAC9C9;
        box-shadow: unset;
        &:hover{
          background-color: #ececec;
        }
        &:focus{
          outline: unset;
        }
      }
    }
  }
  .greebBtn{
    font-size: 13px;
  }
  .bottomTextWrap{
    padding: 10px 10px;
    .link{
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .multiSelectWithCheckbox{
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding: 5px;
    &.Mui-focused{
      
      .MuiInputBase-input{
        background-color: transparent;
      }
    }
    &:hover{
      border: 1px solid #444;
    }
    &::before{
      content: none;
    }
    &:after{
      border-bottom: 0;
    }
  }
}
.CustomFormTitleInput{
  padding-right: 10px;
  margin-bottom: 15px;
  label{
    display: inline-block;
    margin-bottom: 10px;
    font-size: 18px;
  }
  input{
    display: block;
    width: 100%;
    padding: 8px 10px;
    background: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 16px;
    border: 1px solid #a0a0a0;
    &:focus{
      outline: 2px solid transparent;
      border: 2px solid #3f51b5;
    }
  }
}

.createBeneficiaryType{
  .MuiPaper-root{
    padding-left: 0;
    padding-right: 0;
    padding-top: 5px;
    box-shadow: unset;
  }
  .formHeadingWrap{
    margin-left: 0;
    margin-right: 0;
  }
}

.beneficiaryTypeForm{
  .formControl{
    position: relative;
    margin-left: 0;
    margin-right: 0;
    .cross{
      position: absolute;
      right: 30px;
      top: 71%;
      transform: translateY(-50%);
      cursor: pointer;
      opacity: 0.3;
      transition: all 0.25s ease-in;
      &:hover{
        opacity: 0.8;
      }
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
      padding: 10px 12px;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
      padding: 3.5px 0px;
    }
    .MuiAutocomplete-root{
      width: 100% !important;
    }
  }
  .checkBox{
    margin: 0;
    padding: 10px 0;
  }
  .radiobuttonWrap{
    margin: 0;
    padding: 10px 0;
  }
  .optionalField{
    padding-top: 10px;
    margin: 0;
    .formControl{
      margin-top: 5px;
      margin-bottom: 5px;
      .MuiTypography-root{
        font-size: 15px;
        color: rgba(0, 0, 0, 0.67);
      }
    }
    ul{
      padding-top: 0;
      padding-bottom: 15px;
    }
    li{
      padding: 0;
    }
  }
  .MuiCheckbox-root, .MuiRadio-root{
    padding-top: 0;
    padding-bottom: 0;
  }
  .formControlHeading{
    margin: 0 0 10px;
    h3{
      font-size: 0.857rem;
      font-weight: bold;
    }
  }
  .flexRow{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    .rightFlex{
      flex: 1;
    }
    .formControlHeading{
      width: 35%;
      margin-right: 10px;
    }
    .MuiFormGroup-root{
      flex-direction: row;
    }
  }
  .customMultiSelect{
    margin-top: 0;
  }
  .buttonWrap{
    margin: 0;
    padding: 10px 0;
  }
  .customBtn{
    text-transform: capitalize;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.23);
  }
}
.exportBtn{
  padding: 10px 22px !important;
  width:100% !important;
}
.fieldLabel{
  color:#444444 ;
  font-size:16px;
  margin:10px 0
}
.customMultiSelect{
  display: block !important;
  .MuiInputLabel-formControl{
    font-size: 14px;
    transform: translate(14px, 29px) scale(1);
  }
  .MuiInputLabel-shrink{
    transform: translate(0, 1.5px) scale(0.85);
  }
  .MuiInputBase-root{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    &:before, &:after{
      content: none;
    }
    .MuiInputBase-input{
      padding: 10px 14px
    }
  }
}
.customList{
  &.MuiListItem-root.Mui-selected{
    color: darken($green, 15%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  }
}