@import '_variable.scss';

.rightDrawerContainer{
  width: 70vw;
  position: relative;
  padding: 45px 40px;
  .close{
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    transition: all 0.25s ease-in;
    &:hover{
      background-color: #f5f5f5;
    }
    svg{
      width: 22px;
      height: 22px;
    }
  }
  tbody{
    .MuiTableCell-root{
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.rightDrawerBtn{
  padding: 8px 15px;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #CAC9C9;
  cursor: pointer;
  min-height: 36px;
  text-transform: uppercase;
  transition: all 0.25s ease-in;
  &:hover{
    border: 1px solid darken(#CAC9C9, 15%);
    background-color: #f5f5f5;
  }
  &:focus{
    outline: 1px solid darken(#CAC9C9, 15%);
  }
}

.rightdrawerMain{
  .rightDrawerContainer{
    .tabContainerMain{
      padding-top: 10px;
      header{
        box-shadow: unset;
        background-color: transparent;
        border-bottom: 1px solid #ececec;
        div[role="tablist"] .PrivateTabIndicator-root-305{
          height: 0;
        }
        .leftAlignTab{
          max-width: unset;
          text-transform: none;
          padding-left: 0;
          font-weight: normal;
          &.Mui-selected{
            font-weight: bold;
          }
        }
        .MuiTab-textColorPrimary-237.Mui-selected{
          font-weight: bold;
        }
      }
    }
  }
  .headingWrap{
    padding: 10px 16px 10px 0;
    display: flex;
    align-items: center;
    img{
      margin-right: 10px;
    }
    svg{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .heading{
      display: flex;
      align-items: center;
      h3{
        font-size: 14px;
        text-transform: uppercase;
      }
      >span{
        display: inline-block;
        margin-left: 10px;
        background-color: $green;
        padding: 2px 10px;
        border-radius: 20px;
        color: $white;
        font-size: 11px;
      }
    }
  }
}

.selectBeneficiary{
  .searchBoxTable{
    padding: 0px 5px;
    margin: 15px 0 10px;
    border: 1px solid #c0c9c9;
    &:hover, &:focus{
      border: 1px solid darken(#c0c9c9, 15%);
    }
    .serachInput{
      min-height: 35px;
    }
    .iconButton{
      padding: 5px;
    }
  }
}

.createBeneficiaryType{
  // .MuiPaper-root{
  //   box-shadow: unset;
  //   padding-left: 0;
  //   padding-right: 0;
  // }
  // .formControl{
  //   margin-left: 0;
  //   margin-right: 0;
  // }
  .formHeadingWrap{
    h2{
      font-size: 18px;
    }
    .formSubheading{
      color: #9F9F9F;
      font-size: 0.857rem;
    }
  }
}
