@import '_variable.scss';

.tableTopContentMain{
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  @media(min-width: 992px){
    flex-direction: row;
    align-items: center;
  }
  .pageTableTitle{
    h2{
      font-size: 18px;
    }
  }
  .tableTopActionIcons{
    display: flex;
    align-items: center;
    padding-top: 15px;
    flex-wrap: wrap;
    @media(min-width: 992px){
      padding-top: 0;
    }
    .iconWithName{
      display: flex;
      align-items: center;
      margin: 5px 30px 5px 0;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &.download{
        svg{
          border: 2px solid #444;
          border-radius: 50%;
          padding: 1px;
        }
      }
      >span{
        display: inline-block;
        padding-left: 5px;
        font-size: 14px;
      }
    }
  }
}

tbody{
  .statusBtn{
    padding: 5px 15px;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 11px;
  }
  .singleCellLeftRightItems{
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.tableLink{
      &:hover{
        text-decoration: none;
        .name{
          text-decoration: underline;
        }
      }
    }
    .select{
      background-color: $green;
      color: #fff;
      padding: 4px 12px;
      border-radius: 30px;
      font-size: 0.7rem;
      text-transform: uppercase;
    }
  }
  .topNameBottomDesc{
    .name{
      display: block;
      font-size: 18px;
    }
    .description{
      display: block;
      color: #BBB6B6;
      font-size: 14px;
    }
  }
}

.tableLink{
  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  &.MuiBox-root{
    width: fit-content;
    padding: 10px 20px 10px 0;
  }
}
.tableBox{
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  &.marginB80{
    margin-bottom: 80px;
  }
}
.tablePadding{
  padding: 5px 0;
}
.tableEvenOdd{
  .MuiTableRow-root{
    &:nth-child(odd){
      background-color: rgba(0, 0, 0, 0.04)
    }
  }
}

.form_tableMain{
  background-color: #fff;
  .tableLink.MuiBox-root{
    width: 100%;
  }
  >tbody{
    >tr{
      &:hover{
        .deleteIcon{
          .MuiSvgIcon-root{
            opacity: 1;
          }
        }
      }
    }
  }
  .deleteIcon{
    .MuiSvgIcon-root{
      cursor: pointer;
      opacity: 0.3;
      transition: all 0.25s ease-in;
      &:hover{
        fill: red;
      }
    }
  }
}

.searchBoxTable{
  padding: 5px 10px 5px; 
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  background-color: #fff;
  .iconButton{
    padding: 8px;
  }
  .serachInput{
    flex: 1;
    min-height: 35px;
    padding: 5px 10px;
    border: none;
    font-size: 14px;
    &:focus{
      border: none;
      outline: unset;
    }
  }
}

.programTable{
  tbody{
    .statusRow{
      .MuiInput-underline:before{
        border: none;
      }
      .MuiInput-underline:hover:not(.Mui-disabled):before{
        border: none;
      }
      .MuiSelect-select:focus{
        background-color: unset;
        border: none;
      }
      .MuiInputBase-root{
        animation: unset;
        font-size: 14px;
      }
      .MuiInput-underline:after{
        border: unset;
      }
      .redColor{
        color: red;
      }
    }
    .tableLink{
      &:hover{
        text-decoration: none;
      }
    }
    
    .imageWithSliderWrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .leftImageRightName{
        flex: 1;
      }
    }
    .imageTextWrap{
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover{
        .pName{
          text-decoration: underline;
        }
      }
    }
  }
}

.BeneficiariesTable{
  tbody{
    .name{
      display: block;
      font-size: 18px;
    }
    .description{
      display: block;
      color: #BBB6B6;
      font-size: 14px;
    }
    .entries{
      text-align: center;
    }
    .iconCell{
      min-width: 105px;
      .actionIcon{
        margin-left: 10px;
        padding: 5px;
        display: inline-block;
        &:first-child{
          margin-left: 0;
        }
        &:hover{
          svg{
            fill: darken(#BBB6B6, 25%);
          }
        }
        svg{
          width: 20px;
          height: 20px;
          fill: #BBB6B6;
        }
      }
    }
  }
}

.TeamsTable{
  .teamWrap{
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 0;
    .userInfo{
      margin-bottom: 0;
    }
  }
  .teamheadInfo{
    margin-left: 25px;
    >span{
      display: inline-block;
      background-color: $green;
      color: #fff;
      font-size: 0.7rem;
      padding: 2px 12px;
      border-radius: 30px;
    }
  }
  .MuiTableCell-root{
    padding: 15px 25px
  }
  .actionIcon{
    padding: 10px;
    &:hover{
      svg{
        fill: darken(#BBB6B6, 15%);
      }
    }
    svg{
      fill: #BBB6B6;
      width: 20px;
      height: 20px;
    }
  }
}

.customeFormTableMain, .formRevisionsMain{
  .userNameWithImage{
    .userImage{
      .MuiAvatar-root{
        width: 25px;
        height: 25px;
      }
    }
  }
}

.userNameWithImage{
  display: flex;
  align-items: center;
  .userImage{
    .MuiAvatar-root{
      width: 35px;
      height: 35px;
    }
  }
  .userName{
    flex: 1;
    margin-left: 5px;
    text-transform: capitalize;
  }
}

.formRevisionsMain{
  .tableTopContentMain{
    padding-bottom: 15px;
    padding-top: 5px;
  }
  .gobackButton{
    background-color: #fff;
    border: 1px solid #CAC9C9;
    text-transform: capitalize;
    padding-left: 15px;
    padding-right: 15px;
    transition: all 0.25s ease-in;
    &:hover{
      box-shadow: 0px 4px 11px rgba(0,0,0,0.1);
      background-color: $green;
      color: #fff;
    }
  }
  .statusBtn{
    text-transform: capitalize;
    cursor: pointer;
    transition: all 0.25s ease-in;
    &:hover{
      // box-shadow: 0px 4px 11px rgba(0,0,0,0.1);
      background-color: darken($green, 15%) !important;
    }
  }
}
