.backToLink{
  display: inline-block;
  padding-bottom: 20px;
  &:hover{
    svg{
      transform: translateX(-3px);
    }
  }
  svg{
    vertical-align: middle;
    fill: #444;
    width: 30px;
    height: 30px;;
    transition: all 0.25s ease-in;
  }
  span{
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    color: #444;
  }
}