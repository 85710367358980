.groupWrap{
  // display: flex;
  flex-wrap: wrap;
  // background-color: #f7f7f7;
  // padding: 10px;
}
.group{
  padding: 15px;
  border-bottom: 1px solid #ccc;
  // margin-bottom: 15px;
  // margin-right: 10px;
  // border: 1px solid #eee;
  // background-color: #f7f7f7;
  // border-bottom: 1px solid #ccc;
  
}
.groupBody{
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  // padding-left: 15px;
  >label{
    margin-right: 30px;
    svg.MuiSvgIcon-root{
      width: 23px;
      height: 23px;
    }
  }
}
