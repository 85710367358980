@import 'variable';

.modulesWrap{
  padding-left: 25px;
  padding-right: 25px;
  .headingWithLeftIcon{
    padding-top: 20px;
    padding-bottom: 25px;
  }
  .modulesCardMain{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .modulesCrad{
    display: flex;
    width: 24%;
    margin-right: 8px;
    margin-bottom: 15px;
    padding: 12px 15px;
    align-items: flex-start;
    background-color: #ebebeb;
    cursor: pointer;
    transition: all 0.25s ease-in;
    &:hover{
      background-color: darken(#ebebeb, 5%);
      box-shadow: 0px 3px 8px rgba(0,0,0,0.05);
      &.active{
        background-color: darken(#effbed, 5%);
      }
    }
    &.active{
      background-color: #effbed;
      .iconWrap{
        svg{
          fill: #275302;
        }
      }
      .text{
        .name{
          color: #275302;
        }
        .status{
          color: lighten(#275302, 15%);
        }
      }
    }
    .iconWrap{
      svg{
        width: 25px;
        height: 25px;
      }
    }
    .text{
      margin-left: 8px;
      .name{
        font-size: 14px;
        font-weight: bold;
        display: block;
        line-height: 1.2;
      }
      .status{
        font-size: 12px;
        color: lighten($base, 30%);
      }
    }
  }
}