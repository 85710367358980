.dashboardBlocks{
  padding-bottom: 40px;
  .dashboardHead{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 15px 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    .formControl{
      position: relative;
      margin-bottom: 15px;
      margin-left: 15px;
      flex: 1;
      &:first-child{
        margin-left: 0;
      }
      .MuiOutlinedInput-root{
        border-radius: 2px;
      }
      .cross{
        position: absolute;
        top: 23%;
        right: 25px;
        cursor: pointer;
        text-align: center;
        width: 25px;
        height: 25px;
        transition: all 0.25s ease-in;
        border-radius: 50%;
        &:hover{
          background-color: #e8e8e8;
        }
        svg{
          width: 20px;
          vertical-align: middle;
        }
      }
    }
  }
  .dashboardTable{
    .tableLink{
      &:hover{
        text-decoration: underline;
      }
    }
  }
  
}
.title{
  font-size:24px;
  font-weight:bold;
}
.caption{
  color:#BBB6B6;
  font-size:16px;
}
.customCard{
  padding: 20px !important;
}
.dashboardGrid{
  padding-right: 20px;
  padding-bottom:20px;
}