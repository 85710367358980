.programTab{
&.tabContainer{
  margin: 15px 0 30px;
  .MuiTabs-flexContainer{
    justify-content: space-between;
  }
  .MuiTypography-h4{
    font-size: 18px;
    padding-bottom: 10px;
  }
  .MuiBox-root-57{
    padding: 15px;
  }
  .makeStyles-paper-7{
    padding: 5px 0 0;
  }
  .MuiTab-root-82{
    >span{
      text-transform: capitalize !important;
    }
  }
  .Mui-selected{
    font-weight: bold;
    >span{
      text-transform: capitalize !important;
    }
  }

  .TabContentMain{
    .overviewMain{
      padding-top: 10px;
    }
    .goalsTabMain{
      padding-top: 10px;
    }
    .teamTabMain{
      padding-top: 10px;
    }
    .innerDemoData{
      padding: 25px;
    }
    .tabbackLinkMain{
      background-color: #F5F5F5;
      padding: 0 10px;
      margin-bottom: 10px;
      .link{
        display: inline-block;
        cursor: pointer;
        padding: 10px 10px 10px 0;
        &:hover{
          >span{
            color: darken(#BBB6B6, 25%);
          }
          >svg{
            fill: darken(#BBB6B6, 25%);
          }
        }
        >svg{
          vertical-align: middle;
          fill: #BBB6B6;
          width: 32px;
          height: 32px;
          transition: all 0.25s ease-in;
        }
        >span{
          display: inline-block;
          vertical-align: middle;
          color: #BBB6B6;
          font-size: 13px;
          transition: all 0.25s ease-in;
        }
      }
      
    }
  }
}
  .tabButton{
    text-transform: capitalize;
    font-weight: 400;
    color: #444444;
    font-size: 14px;
    letter-spacing: 0;
  }
  .MuiAppBar-colorDefault{
    background-color: #fff;
    border-bottom: 1px solid #E0E0E0;
  }
}