.dashboardRightDrawer{
  .rightBlock_main{
    padding: 20px 0;
    .block{
      padding: 15px 0;
      border-bottom: 1px solid #dddddd;
      &:last-child{
        border-bottom: unset;
      }
      >span{
        display: inline-block;
      }
    }
    .blockName{
      font-size: 15px;
      font-weight: medium;
      margin-right: 10px;
      font-weight: bold;
    }
    .blockData{

    }
    .innerListMain{
      margin-top: 15px;
      .innerList{
        display: block;
        padding: 5px 0;
      }
    }
    .subBlockName{
      margin: 5px 0;
      font-weight: 500;
    }
    .textContent{
      display: block;
    }
  }
}

.dashboardDrawerMain{
  &.rightDrawerContainer{
    width: 85vw;
    @media(min-width: 768px){
      width: 60vw;
    }
    @media(min-width: 1024px){
      width: 45vw;
    }
    @media(min-width: 1600px){
      width: 35vw;
    }
  }
  .drawerInnerTable{
    padding-bottom: 15px;
    thead{
      th{
        background-color: #f3f3f3;
        border: 1px solid #9a9a9a;
      }
    }
    tbody{
      td{
        border: 1px solid #9a9a9a;
        padding: 8px 24px 8px 16px;
        &:last-child{
          padding-right: 16px;
        }
      }
    }
  }
}
.tableLabel{
  font-size:16px;
  
}
.tableSublabel{
  font-size:10px;
  color:#909090;
}