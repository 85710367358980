@import 'variable';

.goalRangeMain{
  padding: 3px 25px 30px;
}
.goalRangeContainer{
  >div{
    padding: 7px 0;
  }
}
.rangeSlider{
  .input-range__track{
    background: linear-gradient(-90deg, #A4C5A8 0, #E1EDC6 60%, #E8F5CD 100%);
    height: 10px;
    border-radius: 0;
    cursor: unset;
    &::before{
      content: '';
      width: 1px;
      height: 22px;
      background:darken(#E0E0E0, 15%);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after{
      content: '';
      width: 1px;
      height: 22px;
      background:darken(#E0E0E0, 15%);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .input-range__track--active{
    background: linear-gradient(90deg, #edbb41 0, #88c260 100%);
    &::after{
      content: unset;
    }
  }
  .input-range__slider{
    border-radius: 0;
    width: 1px;
    height: 16px;
    background:darken(#39C777, 10%);
    border: unset;
    margin-left: 0;
    margin-top: -0.8rem;
    cursor: unset;
  }
  .input-range__label--value{
    top: -30px;
  }
  .input-range__label{
    font-family: 'Roboto', sans-serif;
    color: #444;
  }
  .input-range__label--min, .input-range__label--max{
    bottom: -25px;
  }
  .input-range__label-container{
    left: 0;
  }
}

