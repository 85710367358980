@import 'variable';

.authMainContainer{
  background-color:#fff;
  background-image: url(/tiger11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 165px center;
  min-height: 100vh;
  padding: 0 !important;
  @media(min-width: 600px){
    padding: 0;
  }
  .mainContainer{
    min-height: 100vh;
    .mainContainer_col{
      background-color: #fff;
      padding: 0 55px;
      position: relative;
      .mainContainer_col_inner{
        @media(min-width: 1199px){
          max-width: 355px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .logo{
        max-width: 222px;
      }
      h3{
        font-size: 16px;
        text-align: center;
        color: #444444;
        margin-bottom: 15px;
      }
      .loginForm{
        width: 100%;
        margin-top: 45px;
        .buttonWrap{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          >a{
            color: #444444;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .button{
          background-color: $green;
          color: #fff;
        }
      }
      .bottomText{
        position: absolute;
        bottom: 55px;
        left: 50%;
        transform: translateX(-50%);
        h6{
          color: #BBB6B6;
          font-size: 14px;
        }
      }
    }
  }
}