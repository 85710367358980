.slider{
  width: 100%;
  .slider_inner{
    text-align: center;
    &:hover{
      .slider_text{
        color: #444;
      }
    }
    .slider_numric{
      display: block;
      color: #444444;
      font-size: 16px;
    }
    .slider_text{
      display: block;
      color: #BBB6B6;
      font-size: 14px;
      transition: all 0.25s ease-in;
    }
  }
  .slick-prev, .slick-next{
    color: #DADADA;
    padding: 4px;
    &:hover{
      color: darken(#DADADA, 10%);
    }
    >svg{
      width: 18px;
      height: 18px;
    }
  }
  .slick-arrow::before{
    content: none;
  }
}