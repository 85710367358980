@import 'variable';

.overviewMain{
  // padding-left: 25px;
  // padding-right: 25px;
  >div{
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .headingWithLeftIcon{
    // padding-top: 20px;
    padding-bottom: 25px;
  }
  .whiteButton{
    padding: 10px 18px;
    background-color: #fff;
    border: 1px solid #CAC9C9;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.25s ease-in;
    &:hover{
      background-color: #fefefe;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.18);
    }
    &:focus{
      outline: unset;
    }
  }
  .modulesWrap{
    .headingWithLeftIcon{
      padding-top: 0;
    }
  }
  .userInfo{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .text{
      flex: 1;
      margin-left: 10px;
      .name{
        text-transform: capitalize;
        display: block;
      }
      .description{
        color: #BBB6B6;
        font-size: 12px;
      }
    }
  }

  .goalWrap{
    padding: 20px 25px 25px;
    .goalRangeMain{
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 30px;
    }
    .headingWithLeftIcon{
      padding-bottom: 15px;
    }
  }

  .teamWrap{
    padding-left: 25px;
    padding-right: 25px;
    .headingWithLeftIcon{
      padding-top: 0;
      padding-bottom: 25px;
    }
    .teamCardMain{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      
      .userInfo{
        margin-bottom: 30px;
        // flex: 1;
        width:20%;
      }
    }
  }
  
  .programOverView{
    .headingWithLeftIcon{
      padding-left: 25px;
      padding-right: 25px;
    }
    .innerContentRow{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 25px;
      border-bottom: 1px solid #dadada;
      .tooltipEdit{
        &:hover{
          svg{
            fill: #333;
          }
        }
        svg{
          width: 20px;
          height: 20px;
          fill: #dadada;
        }
      }
      .innerContent1{
        width: 30%;
      }
      .innerContent2{
        flex: 1;
        padding-left: 10px;
        padding-right: 10px;
      }
      .innerContent3{
        width: 15%;
        text-align: right;
      }
    }
    .btnWrap{
      margin-top: 15px;
      padding-left: 25px;
      padding-right: 25px;
      button{
        margin-right: 15px;
      }
    }
  }
}

.headingWithLeftIcon{
  display: flex;
  align-items: center;
  svg{
    width: 20px;
    height: 20px;
  }
  .heading{
    margin-left: 10px;
    font-weight: bold;
  }
}

