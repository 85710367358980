@import 'variable';

  @page {
    // size: 80mm 50mm;
    margin: 30px;
    size: auto;
  }
  @media print{
    body{page-break-after: always;}
    .hideIcon{
      display:none
    }
    .page-break {
      margin: 30px 0;
      display: block;
      page-break-before: auto;
    }
  }
.mainHeader{
  h1{
    &.pageTitle{
      cursor: auto;
    }
  }
  .pageTitle{
    cursor: pointer;
    line-height: 0;
    padding: 10px 0;
    margin-right: 5px;
    font-size: 14px;
    text-transform: capitalize;
    @media(min-width: 769px){
      font-size: 16px;
    }
    @media(min-width: 1024px){
      font-size: 18px;
    }
  }
}

.icon {
  color: $white;
}


  .makeStyles-drawerPaperClose-26 {
    @media (min-width: 300px){
      .menuUlWrap {
        .MuiListItemIcon-root{
          display: inline-flex;
        }
      }
    }
    
  }


.menuUlWrap{
  a.active{
    .MuiListItemText-root{
      color: $activeMenu;
    }
  }
  .MuiListItemIcon-root{
    min-width: 30px;
    display: none;
  }
  .MuiListItemText-root{
    color: $white;
  }
  .MuiListItem-root{
    border-bottom: 1px solid #262626;
    padding: 0;
    cursor: default;
    display: block;
    &.open{
      ul{
        display: block;
      }
      .dropMenuMain{
        .dropArrow{
          svg{
            transform: rotate(-180deg);
          }
        }
      }
    }
    a{
      width: 100%;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .MuiListItemText-primary{
      font-size: 0.95rem;
    }
    .icon{
      width: 22px;
      height: 22px;
    }
    .dropMenuMain{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      >a{
        flex: 1;
      }
      .dropArrow{
        margin-left: 5px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg{
          fill: #fff;
          transition: all 0.25s ease-in;
        }
      }
    }
    ul{
      display: none;
      // transform: scaleY(0);
      border-top: 1px solid #262626;
      transition: all 0.25s ease-in;
      .MuiListItem-root{
        border-bottom: unset;
        .MuiListItemText-primary{
          font-size: 0.9rem;
        }
      }
      a{
        padding: 4px 16px;
      }
    }
  }
}