@import 'variable';

.customDialog{
  .MuiFormLabel-root{
    font-size: 14px;
  }
  .MuiDialogTitle-root{
    padding-bottom: 0px;
  }
  .MuiDialogActions-root{
    display: flex;
    justify-content: space-between;
    padding: 8px 24px 25px;
    >button{
      padding: 6px 25px;
      &.greenBtn{
        &:hover{
          // background-color: darken($green, 10%)!important;
          box-shadow: 0px 4px 10px rgba(0,0,0,0.2);
        }
      }
    }
  }
  .deleteDialogBtn{
    &.MuiDialogActions-root{
      justify-content: flex-start;
      >button{
        margin-right: 15px;
        text-transform: none;
      }
    }
  }
}