.teamTabMain{
  .teamWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.teamWrap{
  padding-left: 25px;
  padding-right: 25px;
  .headingWithLeftIcon{
    padding-top: 20px;
    padding-bottom: 25px;
  }
  .userInfo{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .text{
      flex: 1;
      margin-left: 10px;
      .name{
        text-transform: capitalize;
        display: block;
      }
      .description{
        color: #BBB6B6;
        font-size: 12px;
      }
    }
  }
}