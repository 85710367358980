.profileImageWrap{
  max-width: 190px;
  margin: 0 auto;
}

.userProfileData{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  >span{
    margin-bottom: 25px;
    margin-left: 20px;
    // flex-grow: 48%;
    
    @media (max-width: 767){
      margin-bottom: 20px;
      margin-left: 0
    }
  }
}
.singleProgramGridWrap{
  position: relative;
  .profileImageName{
    display: flex;
  }
  
  .programName{
    flex: 1;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .headsName{
    .MuiTypography-root{
      font-size: 14px;
    }
  }
  .imagePaper{
    padding: 5px;
  }
  .tooltipEdit{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.25s ease-in;
    &:hover{
      background-color: #f1f1f1;
    }
    >svg{
      fill: #3f53b5;
      transition: all 0.25s ease-in;
    }
  }
  .profileImageWrap{
    border: 5px solid #fff;
    width: 136px;
    height: 94px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    >img{
      width: 100%;
    }
  }
}
