.tabContainerMain{
  .makeStyles-paper-7{
    padding-left: 0;
    padding-right: 0;
  }
  .TabContentMain{
    .innerDemoData{
      padding: 25px;
    }
  }
  
}
