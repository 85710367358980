.collapseInnerFormWrap{
  .innerFormContainer{
    display: flex;
  }
  .innerFormItem{
    padding: 15px;
    border: 1px solid #ccc;
    flex: 80%;
    border-bottom: 0;
    &:first-child{
      flex: 20%;
      padding: 15px 10px;
      background-color: #f8f8f8;
      border-right: 0;
    }This is the name which will appear on the EDIT page
    >lable{
      display: block;
      font-weight: bold;
      color: #333;
      margin-bottom: 5px;
    }
    >span{
      display: block;
      color: #666;
      font-size: 12px;
    }
    >input,
    >textarea,
    >select{
      width: 100%;
      padding: 7px 10px;
      border: 1px solid #868686;
      border-radius: 3px;
      &:focus{
        border-color: #2684ff;
        outline-color: #2684ff;
      }
    }
    >select{
      background-color: #fff;
      cursor: pointer;
    }
    textarea{
      resize: vertical;
    }
    .css-yk16xz-control{
      border: 1px solid #868686;
      border-radius: 3px;
      min-height: unset;
      cursor: pointer;
    }
    .css-1okebmr-indicatorSeparator{
      width: 0;
    }
    .css-tlfecz-indicatorContainer{
      padding: 5px;
    }
  }
  input[type='checkbox']{
    cursor: pointer;
  }
  .conditionMain{
    padding: 20px 0;
  }
  .conditionWrapper{
    position: relative;
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    .conditionInner{
      margin-right: 5px;;between
      &:nth-child(2){
        width: 150px;
      }
    }
    lable{
      margin-bottom: 5px;
      display: inline-block;
    }
    input,
    textarea,
    select{
      width: 100%;
      padding: 7px 5px;
      border: 1px solid #868686;
      border-radius: 3px;
      margin-bottom: 10px;
      &:focus{
        border-color: #2684ff;
        outline-color: #2684ff;
      }
    }
    select{
      background-color: #fff;
      cursor: pointer;
    }
    button{
      margin-bottom: 10px;
    }
  }
  .removeBtn{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    &:hover{
      .MuiSvgIcon-root{
        fill: red;
      }
    }
    .MuiSvgIcon-root{
      cursor: pointer;
      font-size: 28px;
      fill: #a0a0a0
    }
  }
  .tableRowAction{
    font-size: 12px;
    color: #f50057;
  }
}