.buttonForMultiSelect{
  position: relative;
  .iconLabelWrap{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    .label{
      margin-left: 5px;
      font-size: 14px;
    }
  }
  
}

.checkBoxList{
  &.MuiMenuItem-root{
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .MuiCheckbox-root{
    padding: 2px 6px;
    .MuiSvgIcon-root{
      width: 22px;
      height: 22px;
    }
  }
  .MuiListItemText-root{
    margin-top: 2px;
    margin-bottom: 2px;
    padding-right: 15px;
    .MuiTypography-body1{
      font-size: 0.97rem;
    }
  }
}