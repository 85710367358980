.goalsTabMain{
  .leftHeadingRightButton{
    padding: 10px 25px;
  }
  .goalsTabMain_table{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    .MuiTableCell-root{
      padding: 5px;
    }
  }
}

.leftHeadingRightButton{
  display: flex;
  justify-content: space-between;
}