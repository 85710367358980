@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
*, *::before, *::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	letter-spacing: 0;
}
body {
	// font-size: 62.5%;
	// font-family: 'Lato', sans-serif;
	// min-height:100vh;
	line-height:1;
	background-color: $lightBackground;
	color: #444444;
}

button{
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0;
}

a, a:link, a:visited {
	text-decoration: none;
	// color: $white;
}
ul {
	list-style: none;
}
.main {
	padding: 50px 20px;
	max-width: 1200px;
	margin: 0 auto;
}
img {
	max-width:100%;
	display: block;
	height: auto;
}

.PT30 {
	padding-top:30px;
}
.PT20 {
	padding-top: 20px;
}
.PT10 {
	padding-top: 10px;
}
.separator {
	height: 0;
	overflow: visible;
	border: 1px solid #dedede;
	margin: 40px 0 0;
	text-align: inherit;
	box-sizing: content-box;
}

.MuiFab-root{
	&.circleButton{
		width: 50px;
		height: 50px;
	}
}

.MuiButton-root{
	&.customBtn{
		width: auto;
		padding: 6px 25px;
	}
	&.borderBtn{
		&.greebBtn{
			color: darken($green, 10%) !important;
			background-color: transparent !important;
			transition: all 0.25s ease-in;
		}
		&:hover{
			&.greebBtn{
				background-color: lighten($green, 45%) !important;
				color: darken($green, 15%) !important;
			}
		}
	}
}
.greebBtn{
	background-color: $green !important;
	color: $white !important;
	border-color: $green !important;
}

.greenBtn{
	background-color: $green !important;
	color: $white !important;
	border-color: $green !important;
	&.disabledBtn{
		background-color: lighten($green, 20%) !important;
	}
}
.inactiveBtn{
	background-color: #bbb6b6 !important;
	color: $white !important;
	border-color: #9c9797 !important;
}

.userImage{
	&.img50x50{
		width: 40px;
		height: 40px;
	}
	&.img40x40{
		width: 40px;
		height: 40px;
	}
	&.imgCircle{
		border-radius: 50%;
	}
}
.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked{
	color: $green;
}
.displayNone{
	display: none;
}

.validationMessage{
	font-size: 0.85rem;
	color: red;
}