.singleProgramGrid{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 599px){
    display: block;
  }
  .imageWithName{
    margin-right: 15px;
  }
  .programSlider{
    flex: 1;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 16px;
    padding-left: 16px;
    margin-top: 20px;
    @media(min-width: 600px){
      margin-top: 0;
      margin-right: 10px;
    }
    @media(min-width: 1024px){
      max-width: 290px;
    }
    @media(min-width: 1024px){
      max-width: 345px;
    }
    @media(min-width: 1055px){
      max-width: 400;
    }
    @media(min-width: 1200px){
      max-width: 600px;
    }
  }
}