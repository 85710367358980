@import '_variable.scss';

.Beneficiaries{
  .headingWrap{
    padding: 16px 16px;
    display: flex;
    align-items: center;
    svg{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    .heading{
      display: flex;
      align-items: center;
      h3{
        font-size: 15px;
      }
      >span{
        display: inline-block;
        margin-left: 10px;
        background-color: #dedede;
        padding: 2px 10px;
        border-radius: 20px;
        color: #424242;
        font-size: 11px;
        &.active{
          background-color: $green;
          color: #fff;
        }
      }
    }
  }
  .leftTextRightSearchAndBtn{
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rightContent{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .buttonWrap{
        margin-left: 10px;
      }
    }
  }
  .searchBoxTable{
    border: 1px solid #cac9c9;
    padding: 0;
    min-height: 36px;
    &:hover, &:active, &:focus{
      border: 1px solid darken(#cac9c9, 15%)
    }
    .serachInput{
      min-height: auto;
      padding: 5px 10px;
    }
    .iconButton{
      padding: 6px;
      svg{
        width: 22px;
        height: 22px;
      }
    }
  }
}

