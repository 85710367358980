@import '_variable.scss';

.tableActionIcons{
  max-width: 100px;
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  .actionIcon{
    padding: 5px 0;
    margin: 0 8px;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      svg{
        fill: darken($lightGrey, 25%)
      }
    }
    svg{
      fill: $lightGrey;
      width: 20px;
      height: 20px;
    }
    &.delete{
      &:hover{
        svg{
          fill: red;
        }
      }
    }
    &.active{
      &:hover{
        svg{
          fill: darken($green, 10%)
        }
      }
      svg{
        fill: $green;
      }
    }
  }
}