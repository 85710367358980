.collapseTableWapper{
  &.MuiTableRow-root{
    vertical-align: top;
  }
  &.openCollpse{
    background-color: #e8eaf6;
    // >td{
    //   color: #fff;
    //   &:hover{
    //     color: #fff;
    //   }
    // }
  }
  >td{
    line-height: 1;
  }
  .tableLable{
    font-weight: bold;
    color: #01579b;
    cursor: pointer;
    transition: all 0.25s ease-in;
    &:hover{
      text-decoration: underline;
      color: #039be5
    }
  }
  .order{
    .MuiBox-root{
      width: 30px;
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: move;
    }
  }
  .tableCell{
    &:hover{
      .rowAction{
        opacity: 1;
      }
    }
  }
  .rowAction{
    font-size: 12px;
    color: #039be5;
    opacity: 0;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
    margin-right: 7px;
    &.rowDelete{
      color: #ca0000;
    }
  }
}